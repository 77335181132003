
import './App.css';
import './responsive.css'
// import './css/animation.css'
// import './css/aos.css'
// import './css/base.css'
// import './css/bootstrap.min.css'
// import './css/demo_three.css'
// import './css/flaticon.css'
// import './css/gr_css.css'
// import './css/magnific-popup.css'
// import './css/nouislider.min.cc'
// import './css/pbminfotech-base-icons'
// import './css/responsive.css'
// import './css/shortcode.css'
// import './css/style.css'
// import './css/swiper.min.css'
// import './css/themify-icons.css'
// import './js/aos.js'
// import './js/bootstrap.js'
// import './js/circle-progress.js'
// import './js/jquery.appear.js'
// import './js/jquery.countdown.min.js'
// import './js/jquery.loan-calculator.js'
// import './js/jquery.magnific-popup.min.js'

import Navbar from './compoments/Navbar';
import Workshop from './compoments/Workshop';
import Footer from './compoments/Footer';


function App() {
  return (
    <div className="App">
      <Navbar/>
      <Workshop/>
      <Footer/>
    </div>
  );
}

export default App;
