import '../Workshop.css';
import '../responsive.css';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import calender from '../calender.png';

function Workshop() {
    const [attendanceRecords, setAttendanceRecords] = useState([]);

    useEffect(() => {
        const fetchAttendance = async () => {
            try {
                const response = await axios.get('http://103.89.44.59/postemsdataapi/api/GetEmsAttData?attDate=2024-07-03', {
                    headers: {
                        'X-API-KEY': 'vhLJffoZDytpIw8t/AQVVSfDwk/KK5piaW7vm2Nnk9yaW+2yA1sX/3FpEnBBp1Mj'
                    }
                });
                setAttendanceRecords(response.data);
            } catch (error) {
                console.error("Error fetching attendance records:", error);
            }
        };

        fetchAttendance();
    }, []);

    return (
        <div>
            <section className="workshop-section">
                <div className="workshop-image">
                    <img src="https://cdn.shopify.com/s/files/1/0104/8733/2915/files/laptop.jpg?v=1659687103" alt="" />
                </div>
            </section>

            <section className="upcoming-section section-gapping">
                <div className="container">
                    <div className="upcoming">
                        {attendanceRecords.map((record, index) => (
                            <div className="upcoming-detail" key={index}>
                                <div className="upcoming-image">
                                    <img src={calender} alt="" />
                                    <div className="close">
                                        <span>CLOSE IN</span><br />
                                        <span>{record.check_out ? 'Closed' : 'Ongoing'}</span>
                                    </div>
                                </div>

                                <div className="upcoming-info">
                                    <div className="upcoming-text">
                                        <h3>Employee ID: {record.employee}</h3>
                                        <p>Attendance Date: {record.attendance_date}</p>
                                        <p>Check In: {record.check_in}</p>
                                        <p>Check Out: {record.check_out ? record.check_out : 'Not yet checked out'}</p>
                                    </div>
                                    <div className="register">
                                        <a href="/">Register now</a>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Workshop;
