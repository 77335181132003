import React, { useState } from 'react';
import '../navbar.css';
import logo from '../logo.jpg'; // Replace with actual logo path

function Navbar() {
  const [activeTab, setActiveTab] = useState('SHOP'); // Default active tab is SHOP
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div>
      {/* Top Navbar */}
      <div className="top-navbar">
  <div
    className={`nav-item learn ${activeTab === 'SHOP' ? 'active' : ''}`}
    onClick={() => setActiveTab('SHOP')}
  >
    <span>SHOP</span>
  </div>

  <div
    className={`nav-item learn ${activeTab === 'LEARN' ? 'active' : ''}`}
    onClick={() => setActiveTab('LEARN')}
  >
    <span>LEARN</span>
  </div>

  <div
    className={`nav-item learn ${activeTab === 'CONSULTANCY' ? 'active' : ''}`}
    onClick={() => setActiveTab('CONSULTANCY')}
  >
    <span>CONSULTANCY</span>
  </div>
</div>


      {/* Main Navbar */}
      <div className="main-navbar">
        <div className="logo">
          <img src={logo} alt="Logo" />
        </div>

        <div className={`nav-links ${isMobileMenuOpen ? 'open' : ''}`}>
          <ul>
            <li><a href="/">Shop</a></li>
            <li><a href="/">B.Y.O.B.</a></li>
            <li><a href="/">NEW: PISTA BADAAM PROTEIN</a></li>
            <li><a href="/">Reviews</a></li>
            <li><a href="/">Newsletter</a></li>
            <li><a href="/">Our Story</a></li>
          </ul>
        </div>

        <div className="nav-icons">
          <a href="/account"><i className="fa fa-user"></i></a>
          <a href="/search"><i className="fa fa-search"></i></a>
          <a href="/cart"><i className="fa fa-shopping-bag"></i></a>
        </div>

        {/* Hamburger menu icon */}
        <div className="hamburger" onClick={toggleMobileMenu}>
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
