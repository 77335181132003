import '../footer.css'
import React from 'react'

function Footer() {
  return (
    <div>
      <div className="footer-section">
        <div className="container">
            <div className="footer">
            
                <ul>
                <h3>INFORMATION</h3>
                <li>Privacy & policy</li>
                    <li>Privacy & policy</li>
                    <li>Privacy & policy</li>
                    <li>Privacy & policy</li>
                    <li>Privacy & policy</li>
                    <li>Privacy & policy</li>
                </ul>
                
                <ul>
                <h3>Social media</h3>
                <li>Privacy & policy</li>
                    <li>Privacy & policy</li>
                    <li>Privacy & policy</li>
                    <li>Privacy & policy</li>
                    <li>Privacy & policy</li>
                    <li>Privacy & policy</li>
                </ul>

                
                <ul>
                <h3>INFORMATION</h3>
                <li>Privacy & policy</li>
                    <li>Privacy & policy</li>
                    <li>Privacy & policy</li>
                    <li>Privacy & policy</li>
                    <li>Privacy & policy</li>
                    <li>Privacy & policy</li>
                </ul>
                    
                    
                    
                
            </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
